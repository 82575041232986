<template>
    <div class="form-scc">
        <el-form ref="form" :rules="formDataRules" label-position="right" class="left_10" :model="form" label-width="80px">
            <el-form-item label="状态" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio label="0">展示</el-radio>
                    <el-radio label="1">不展示</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="轮播间隔" prop="rotationInterval">
                <el-input class="small-input"
                          placeholder="请输入轮播间隔"
                          v-model="form.rotationInterval"></el-input><span style="margin-left: 5px">秒</span>
            </el-form-item>
            <el-form-item label="等待时长" prop="waitingTime">
                <el-input class="small-input"
                          placeholder="请输入等待时长"
                          v-model="form.waitingTime"></el-input><span style="margin-left: 5px">分钟</span>
            </el-form-item>
            <el-form-item label="广告素材"></el-form-item>
                <el-form-item class="ad_class" label="轮播位1">
                    <el-input class="small-input" v-model="form.carousel1"></el-input>
                    <el-upload
                            class="upload_ad"
                            :auto-upload="false"
                            action=""
                            accept="JPEG、TIFF、RAW、BMP、GIF、PNG"
                            :show-file-list="showFileList"
                            :on-change="handleFile"
                    >
                        <el-button size="small" :loading="loading1" @click="getFileIndex('1')" icon="el-icon-upload2" type="primary"></el-button>
                    </el-upload>
                    <el-button style="margin-left: 5px" size="small" @click="seeFile('1')" icon="el-icon-view" type="primary"></el-button>
                </el-form-item>
                <el-form-item class="ad_class" label="轮播位2">
                    <el-input class="small-input" v-model="form.carousel2"></el-input>
                    <el-upload
                            class="upload_ad"
                            :auto-upload="false"
                            action=""
                            :show-file-list="showFileList"
                            :on-change="handleFile"
                            >
                        <el-button size="small" :loading="loading2" @click="getFileIndex('2')" icon="el-icon-upload2" type="primary"></el-button>
                    </el-upload>
                    <el-button style="margin-left: 5px" size="small" @click="seeFile('2')" icon="el-icon-view" type="primary"></el-button>
                </el-form-item>
                <el-form-item class="ad_class" label="轮播位3">
                    <el-input class="small-input" v-model="form.carousel3"></el-input>
                    <el-upload
                            class="upload_ad"
                            :auto-upload="false"
                            action=""
                            :show-file-list="showFileList"
                            :on-change="handleFile"
                            >
                        <el-button size="small" :loading="loading3" @click="getFileIndex('3')" icon="el-icon-upload2" type="primary"></el-button>
                    </el-upload>
                    <el-button style="margin-left: 5px" size="small" @click="seeFile('3')" icon="el-icon-view" type="primary"></el-button>
                </el-form-item>
                <el-form-item class="ad_class" label="轮播位4">
                    <el-input class="small-input" v-model="form.carousel4"></el-input>
                    <el-upload
                            class="upload_ad"
                            :auto-upload="false"
                            action=""
                            :show-file-list="showFileList"
                            :on-change="handleFile"
                            >
                        <el-button size="small" :loading="loading4" @click="getFileIndex('4')" icon="el-icon-upload2" type="primary"></el-button>
                    </el-upload>
                    <el-button style="margin-left: 5px" size="small" @click="seeFile('4')" icon="el-icon-view" type="primary"></el-button>
                </el-form-item>
            <el-form-item label="生效范围" prop="scopeArr">
                <Select :options="options" v-model="form.scopeArr" class="small-input" @selected="selected"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>

    import Select from '@/views/components/Select'

    export default {
        name: "AddAd",
        computed: {
            adId() {
                return this.$route.query.id
            },
        },
        components: {
            Select,
        },
        data() {
            let checkScopeArr = (rule, arr, callback) => {
                if (arr.length == 0) {
                    return callback(new Error('值不能为空'))
                }else {
                    callback();
                }
            }
            return {
                fileIndex:'',
                seeIndex:'',
                checkNum:0,
                showFileList:false,
                options: [],
                loading:false,
                loading1:false,
                loading2:false,
                loading3:false,
                loading4:false,
                form: {
                    id:'',
                    status: '',
                    rotationInterval: '',
                    waitingTime: '',
                    carousel1: '',
                    carousel2: '',
                    carousel3: '',
                    carousel4: '',
                    scope: '',
                    scopeArr: [],
                    fileList:[],
                    files:[],
                },
                formDataRules: {
                    status: [
                        { required: true, message: '请选择', trigger: 'change' }
                    ],
                    rotationInterval: [
                        {required: true, message: '请输入大于0的整数',trigger: 'blur'},
                        { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数'}
                    ],
                    waitingTime: [
                        {required: true, message: '请输入大于0的整数',trigger: 'blur'},
                        { pattern: /^\+?[1-9]\d*$/, message: '请输入大于0的正整数'}
                    ],
                    scopeArr: [
                        {required: true, validator: checkScopeArr,trigger: 'change'}
                    ]
                },
            }
        },
        methods: {
            onSubmit() {
                this.loading = true;
                this.$axios.post('/sys-api/file-api/saveUploadFile',this.form,(res) => {
                    this.loading = false;
                    if(res.code === '100'){
                        this.$message.success("保存成功");
                        this.$router.push({name: 'AdList'})
                    }else {
                        this.$message.error("保存失败");
                    }
                })
            },
            selected(value){
                this.form.scopeArr = value;
            },
            getDepartmentList() {
                let _this=this;
                this.$axios.get("/sys-api/site-api/getDepartment", {}, (res) => {
                    if (res.code === '100') {
                        res.data.forEach(function (data) {
                            let check = false;
                            for (let i = 0; i < _this.form.scopeArr.length; i++) {
                                let scope = _this.form.scopeArr[i];
                                if (data.id == scope){
                                    check = true;
                                    break;
                                }
                            }
                            _this.options.push({
                                value: data.id,
                                label: data.name,
                                check: check
                            })
                        });
                    }
                })
            },
            handleFile(file) {
                let ff = file.raw;
                this["loading" + this.fileIndex] = true;
                let formData = new FormData();
                formData.append('file',ff);
                formData.append('level',this.fileIndex);
                this.$axios.form('/sys-api/file-api/uploadFile',formData,(res) => {
                    this["loading" + this.fileIndex] = false;
                    if(res.code === '100'){
                        let com = "carousel" + this.fileIndex;
                        this.form[com] = file.name;
                        for (let i = 0; i < this.form.fileList.length; i++) {
                            let data = this.form.fileList[i];
                            if (data.adLevel == res.data.adLevel){
                                this.form.fileList = this.form.fileList.splice(i,1);
                            }
                        }
                        this.form.fileList.push(res.data);
                        this.$message.success("上传成功");
                    }else {
                        this.$message.error("上传失败");
                    }
                })
            },
            getFileIndex(index){
                this.fileIndex = index;
            },
            seeFile(index){
                let url = "";
                for (let i = 0; i < this.form.fileList.length; i++) {
                    let data = this.form.fileList[i];
                    if (data.adLevel == index){
                        url = data.fileUrl;
                    }
                }
                window.open(url);
            },
            getAdInfo(id){
                this.$axios.get("/sys-api/ad/getAdInfo", {id:id}, (res) => {
                    if (res.code === '100') {
                        this.form = res.data;
                        for (let i = 0; i < this.form.fileList.length; i++) {
                            let data = this.form.fileList[i];
                            let com = "carousel" + data.adLevel;
                            this.form[com] = data.realName;
                        }
                        this.getDepartmentList();
                    }
                })
            },
            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4";
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                return uuid;
            }
        },
        mounted() {
            if (typeof (this.adId) != 'undefined'){//详情
                this.getAdInfo(this.adId);
            }else {//新增
                this.getDepartmentList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .form-scc{
        margin-top: 10px;
        margin-left: 5px;
    }
    .small-input {
        width: 300px;
    }
    .ad_class{
        margin-left: 80px;
    }
    .upload_ad{
        margin-left: 5px;
        display: inline-block;
    }

    .content .page-right .main > div[data-v-df95165a] {
        height: auto;
    }
</style>